import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Statistic, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBridge, faCross, faMonument,faTrain, faUser, faPlaceOfWorship, faPersonDigging /* other icons you want to use */ } from '@fortawesome/free-solid-svg-icons';

function ZoneTypeStat() {
  const [zoneTypes, setZoneTypes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const currOwner = localStorage.getItem('username');
  
    axios.get(`https://node.debruin.se/zonetype?username=${currOwner}`)
      .then(response => {
        setZoneTypes(response.data);
      })
      .catch(error => {
        setError(error);
      });
  }, []);
  
  const getIconForType = (type) => {
    switch(type) {
      case 'Bridge': return faBridge;
      case 'Holy': return faCross;
      case 'Monument': return faMonument;
      case 'Regular': return faUser;
      case 'Ruins/Ancient Remains': return faPersonDigging;
      case 'Train Station': return faTrain;
      case 'Castle/Fort': return faPlaceOfWorship;
      // Add cases for other types with appropriate Font Awesome icons
      default: return 'question-circle'; // Use a default Font Awesome icon
    }
  };

  return (
    <Message>
      <h2>Zontyper</h2>
      <Statistic.Group widths={zoneTypes.length.toString()}>
        {zoneTypes.map((ztype, index) => (
          <Statistic key={index}>
            <Statistic.Value>
              <FontAwesomeIcon icon={getIconForType(ztype.type)} />
              
            </Statistic.Value>
            <Statistic.Label>{ztype.type} ({ztype.Antal})</Statistic.Label>
          </Statistic>
        ))}
      </Statistic.Group>
    </Message>
  );
}

export default ZoneTypeStat;
