import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TakenToday from '../takenToday'; // Import the TakenToday component
import { Card } from 'semantic-ui-react';

function MedalImages({ medals }) {
    return (
      <div>
        {medals.map((medalId) => {
          // Assuming medalId is a number
          const imageUrl = `/img/${medalId}.png`; // Construct the image URL
          return (
            <img
              key={medalId}
              src={imageUrl}
              alt={`Medal ${medalId}`}
              style={{ width: '50px', height: '50px' }} // Adjust the style as needed
            />
          );
        })}
      </div>
    );
  }

function UserDetails() {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => { // User details from turfAPI
        const userName = localStorage.getItem('username') || "defaultUsername";
        axios.post('https://node.debruin.se/userdata', { name: userName }, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
           // Parse medals and currentZones into arrays
           const parsedData = response.data.map(user => ({
            ...user,
            medals: JSON.parse(user.medals),
            currentZones: JSON.parse(user.currentZones)
        }));
        setUserData(parsedData);
    })
    .catch(error => {
        setError(error);
    });
}, []);





    let zoneIds = [];

    if (userData) {
      // Assuming each user object in userData has a 'zones' array
      userData.forEach(user => {
        zoneIds = [...zoneIds, ...user.currentZones];
      });
      zoneIds = [...new Set(zoneIds)]; // Remove duplicates
    }

    return (

    <div>
        {userData && (
            <div>
            
                <Card.Group>
                    {userData.map(user => (
                    <Card key={user.id}>
                        <Card.Content>
                        <Card.Header>{user.name}</Card.Header>
                        <Card.Meta>Region: {user.region_name}</Card.Meta>
                        <Card.Meta>Poäng denna runda: {user.points} ({user.pointsPerHour})</Card.Meta>
                        <Card.Description>
                        <MedalImages medals={user.medals} />
                            {/* Add more user details here */}
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    ))}
                </Card.Group>
                
                    
                    <TakenToday />
            </div>
            )}

            {/* Error message */}
            {error && <p>Error: {error.message}</p>}

            {/* Display other data */}
            {/* ... */}
        </div>

    );
}
export default UserDetails;