import React, { useContext } from 'react';
import { UserContext } from '../../UserContext'; // Import the context
import { Button, Icon } from 'semantic-ui-react';

const LogoutButton = () => {
  const { clearUserData } = useContext(UserContext);

  const handleLogout = () => {
    // Clear user session data
    localStorage.removeItem('authToken');
    localStorage.removeItem('username');
    
    clearUserData(); // Clear global user data

    window.location.href = '/login';
  };

  return (
    <Button onClick={handleLogout} icon labelPosition='left'>
    <Icon name='sign-out' />
    Logga ut
  </Button>
  );
};

export default LogoutButton;