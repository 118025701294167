import React, { createContext, useState } from 'react';

// Create the context
export const UserContext = createContext();

// Create a Provider component
export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [zoneDetails, setZoneDetails] = useState([]);

  const clearUserData = () => {
    setUserData(null);
    setZoneDetails([]);
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, zoneDetails, setZoneDetails, clearUserData }}>
      {children}
    </UserContext.Provider>
  );
};
