import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './Root'; // Ensure this is the correct path to your Root component
import 'semantic-ui-css/semantic.min.css';
import './index.css'; // Ensure this is the correct path to your CSS file

const container = document.getElementById('root');
const root = createRoot(container); // Make sure 'root' is the ID of your root div in your public/index.html

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
