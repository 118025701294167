import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login'; // Make sure this component exists
import LogoutButton from './components/LogoutButton'; // Import the LogoutButton component 
import ZoneDetails from './components/zoneDetails'; // Import the ZoneDetails component
import PublicFeed from './components/publicFeed'; // Import the PublicFeed component
import UserDetails from './components/userDetails'; // Import the PublicFeed component
import { Tab,Grid, Container, Header, Message, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './App.css'; 
import ZoneTypeStat from './components/zoneTypeStat';


const App = () => {
  document.title = 'Turfinfo';
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const rightAlignStyle = {
    textAlign: 'right',
  };

  const [zoneIds, setZoneIds] = useState([]);

  useEffect(() => {
    const userName = localStorage.getItem('username') || "defaultUsername";
    axios.post('https://node.debruin.se/userdata', { name: userName }, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
         // Parse medals and currentZones into arrays
         const parsedData = response.data.map(user => ({
          ...user,
          medals: JSON.parse(user.medals),
          currentZones: JSON.parse(user.currentZones)
      }));
      setUserData(parsedData);
    })
    .catch(error => {
      setError(error);
    });
  }, []);

  useEffect(() => {
    if (userData) {
      let newZoneIds = userData.reduce((acc, user) => [...acc, ...user.currentZones], []);
      setZoneIds([...new Set(newZoneIds)]);
    }
  }, [userData]);

  const panes = [
    {
      menuItem: {
        key: 'startpage',
        icon: 'home', // Specify an icon name here
        content: 'Start'
      },
      render: () => (
        <Tab.Pane>
          <Grid>
            {/* Your current content */}
            <Grid.Row>
              <Grid.Column width={5}>
                <UserDetails />
              </Grid.Column>
              <Grid.Column width={5}>
                <Message>
                  <h2>Dina zoner</h2>
                </Message>
                <Message>
                  <ZoneDetails zoneIds={zoneIds} />
                </Message>
              </Grid.Column>
              <Grid.Column width={6} style={rightAlignStyle}>
                <Message>
                  <h2>Takeovers</h2>
                </Message>
                <Message>
                  <PublicFeed />
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'Statistik',
        icon: 'chart pie', // Specify an icon name here
        content: 'Statistik'
      },
      render: () => <Tab.Pane><ZoneTypeStat/></Tab.Pane>
    },
    {
      menuItem: {
        key: 'userprofile',
        icon: 'user', // Specify an icon name here
        content: 'Min profil'
      },
      render: () => <Tab.Pane>Content for Placeholder Tab 2</Tab.Pane>
    },
  ];

  const MainContent = () => {
    return (
      <Container style={{ marginTop: '20px' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Message>
                <Header as='h1' icon>
                  <Icon name='user circle' />
                  Martins TURFjox
                </Header>
                <div style={rightAlignStyle}><LogoutButton /></div>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
<p> </p>
        <Tab panes={panes} />
      </Container>
    );
  };

return (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<ProtectedRoute><MainContent /></ProtectedRoute>} />
    </Routes>
  </Router>
);
};
export default App;