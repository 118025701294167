import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { List, Message } from 'semantic-ui-react';


function getOwnerStyle(currOwner) {
    // Complex logic to determine the style based on currOwner
    if (currOwner === 'tbone') {
      return { color: 'red' };
    } else if (currOwner === 'Rektorinnan') {
      return { color: 'green' };
    } else {
      return { color: 'black' };
    }
  }

  function formatTime(datetime) {
    const date = new Date(datetime);
    return date.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });
  }



  const PublicFeed = () => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
  
    // Function to fetch events data
    const fetchEvents = () => {
        axios.get('https://node.debruin.se/events')
            .then(response => {
                setEvents(response.data);
            })
            .catch(error => {
                setError(error);
            });
    };

    useEffect(() => {
        fetchEvents(); // Initial fetch
        const interval = setInterval(fetchEvents, 5 * 60 * 1000); // Polling every 5 min

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);
  
    if (error) {
        return <Message error header="Error" content="Failed to fetch events." />;
    }
  


    return (
        <div className="zone-list-container">
          <List>
            {events.map(event => (
              <List.Item key={event.id}>
                <List.Content>
                      <List.Header>{event.tid}</List.Header>
                      <List.Description>
                        {formatTime(event.Tid)}
                      </List.Description>
                      <List.Description>
                      <span style={getOwnerStyle(event.currOwner)}>{event.currOwner}</span> tog <b>{event.name}</b> från {event.prevOwner}
                      </List.Description>
                    </List.Content>
              </List.Item>
            ))}
          </List>
          {events.length === 0 && <Message info>No events to display.</Message>}
        </div>
      );
    };
  export default PublicFeed