import React from 'react';
import App from './App'; // Your main app component
import { UserProvider } from './UserContext'; // Your context provider

const Root = () => {
  return (
    <UserProvider>
      <App /> {/* App contains the Router */}
    </UserProvider>
  );
};

export default Root;
