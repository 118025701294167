import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { List, Icon, Accordion } from 'semantic-ui-react';


function calculateTimeElapsed(takeOverTime) {
    const currentTime = new Date();
    const timeDifference = currentTime - new Date(takeOverTime);

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} dagar, ${hours} timmar, ${minutes} minuter`;
  }

  const organizeZonesByRegion = (zones) => {
    const regions = {};
    zones.forEach(zone => {
      if (!zone.region) {
        console.error('Zone without region:', zone); // Log unexpected zone data
      }
      const region = zone.region || 'Unexpected'; // Change 'Other' to 'Unexpected' for clarity
      if (!regions[region]) {
        regions[region] = [];
      }
      regions[region].push(zone);
    });
  
    // Sort zones alphabetically within each region
    Object.keys(regions).forEach(region => {
      regions[region].sort((a, b) => {
        // Assuming 'name' is the property to sort by. Adjust if necessary.
        return a.name.localeCompare(b.name);
      });
    });
  
    return regions;
  };

const ZoneDetails = ({ zoneIds }) => {
    const [zoneDetails, setZoneDetails] = useState([]);  // Correctly placed useState
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeIndex, setActiveIndex] = useState(-1);  // State for accordion
    const currentUser = localStorage.getItem('username');
    const [nestedActiveIndex, setNestedActiveIndex] = useState({}); // State for nested accordion
    console.log("ZD_Zone IDs:", zoneIds);

  useEffect(() => {
    if (zoneIds && zoneIds.length > 0 && currentUser) {
      setLoading(true);
      const fetchZoneDetails = zoneIds.map(zoneId => {
        const url = `https://node.debruin.se/zone-details/${zoneId}/${currentUser}`;
        return axios.get(url).then(response => response.data);
      });

      Promise.all(fetchZoneDetails)
        .then(data => {
          setZoneDetails(data);
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [zoneIds, currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const zonesByRegion = organizeZonesByRegion(zoneDetails);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleZoneClick = (regionIndex, zoneIndex) => {
    // Create a unique key for each zone based on region and zone index
    const key = `${regionIndex}-${zoneIndex}`;
    const newIndex = nestedActiveIndex[key] === zoneIndex ? -1 : zoneIndex;
    setNestedActiveIndex({ ...nestedActiveIndex, [key]: newIndex });
  };


  return (
    <Accordion styled fluid>
      {Object.keys(zonesByRegion).map((region, regionIndex) => (
        <React.Fragment key={region}>
          <Accordion.Title active={activeIndex === regionIndex} index={regionIndex} onClick={handleClick}>
            <Icon name='dropdown' />
            {region}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === regionIndex}>
            <Accordion styled fluid>
              {zonesByRegion[region].map((zone, zoneIndex) => (
                <React.Fragment key={zoneIndex}>
                  <Accordion.Title
                    active={nestedActiveIndex[`${regionIndex}-${zoneIndex}`] === zoneIndex}
                    index={zoneIndex}
                    onClick={() => handleZoneClick(regionIndex, zoneIndex)}
                  >
                    <Icon name='dropdown' />
                    {zone.name}
                  </Accordion.Title>
                  <Accordion.Content active={nestedActiveIndex[`${regionIndex}-${zoneIndex}`] === zoneIndex}>
                    <p>Area: {zone.area}</p>
                    <p>Poäng: {zone.tp} ({zone.pph})</p>
                    <p>Du har haft denna zon i: {calculateTimeElapsed(zone.takeOverTime)}</p>
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </Accordion>
          </Accordion.Content>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

export default ZoneDetails;
