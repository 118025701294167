import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { List, Message } from 'semantic-ui-react';

function TakenToday() {
  const [takenToday, setTakenToday] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const currOwner = localStorage.getItem('username');
  
    axios.get(`https://node.debruin.se/takentoday/${currOwner}`)
      .then(response => {
        console.log('Data received:', response.data);
        setTakenToday(response.data);
        setRowCount(response.data.length);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }, []);
  

  return (
    <Message>
      <h2>Dagens zoner [{rowCount}]</h2>
      <List>
  {takenToday.map(event => (
    <List.Item key={event.id}>
      <List.Content>
        {event.Tid} | <b>{event.name}</b>
      </List.Content>
    </List.Item>
  ))}
</List>
    </Message>
  );
}

export default TakenToday;
